import React, { useState, useEffect } from "react";
import Link from "next/link";

import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";

export default function Landing() {
  const [asyncSiteConfig, setAsyncSiteConfig] = useState(siteConfig);

  useEffect(() => {
    async function loadConfig() {
      const config = await Promise.resolve(siteConfig);
      setAsyncSiteConfig(config);
    }

    loadConfig().catch((err) => {
      console.error("Failed to load site config", err);
    });
  }, []);

  return (
    <>
      <section className="space-y-6 pb-8 pt-6 md:pb-12 md:pt-10 lg:py-32">
        <div className="container flex max-w-[64rem] flex-col items-center gap-4 text-center">
          {/* Updated link to reflect QuiklyAI's Twitter handle */}
          <Link
            href={asyncSiteConfig.links.twitter}
            className="rounded-2xl bg-muted px-4 py-1.5 text-sm font-medium"
            target="_blank"
          >
            Follow QuiklyAI on Twitter
          </Link>
          <h1 className="font-heading text-3xl sm:text-5xl md:text-6xl lg:text-7xl">
            Empower Your Sales Engineering with AI-driven Insights
          </h1>
          <p className="max-w-[42rem] leading-normal text-muted-foreground sm:text-xl sm:leading-8">
            QuiklyAI enhances your sales and engineering efforts with an
            intelligent document knowledge base, providing instant answers and
            insights.
          </p>
          <div className="space-x-4">
            <Link href="/register" className={cn(buttonVariants({ size: "lg" }))}>
              Sign Up Now
            </Link>
            {/* Link to QuiklyAI's GitHub repository for open source projects */}
            <Link
              href={asyncSiteConfig.links.github}
              target="_blank"
              rel="noreferrer"
              className={cn(buttonVariants({ variant: "outline", size: "lg" }))}
            >
              Explore on GitHub
            </Link>
          </div>
        </div>
      </section>
      <section
        id="features"
        className="container space-y-6 bg-slate-50 py-8 dark:bg-transparent md:py-12 lg:py-24"
      >
        <div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
          <h2 className="font-heading text-3xl leading-[1.1] sm:text-3xl md:text-6xl">
            Key Features of QuiklyAI
          </h2>
          <p className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
            QuiklyAI offers a range of features designed to streamline your
            sales engineering process, from AI-driven Q&A to comprehensive
            analytics.
          </p>
        </div>
        {/* Assume below are QuiklyAI's feature highlights; adjust icons and text as needed */}
        <div className="mx-auto grid justify-center gap-4 sm:grid-cols-2 md:max-w-[64rem] md:grid-cols-3">
          <div className="relative overflow-hidden rounded-lg border bg-background p-2">
            <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
              {/* Replace with an appropriate SVG icon */}
              <svg viewBox="0 0 24 24" className="h-12 w-12 fill-current">
                {/* SVG path here */}
              </svg>
              <div className="space-y-2">
                <h3 className="font-bold">AI-driven Insights</h3>
                <p className="text-sm text-muted-foreground">
                  Harness the power of artificial intelligence to uncover deep
                  insights within your technical documents, enabling faster,
                  more informed decision-making.
                </p>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-lg border bg-background p-2">
            <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
              {/* Replace with an appropriate SVG icon */}
              <svg viewBox="0 0 24 24" className="h-12 w-12 fill-current">
                {/* SVG path here */}
              </svg>
              <div className="space-y-2">
                <h3 className="font-bold">Comprehensive Knowledge Base</h3>
                <p className="text-sm text-muted-foreground">
                  {`Build a centralized repository of knowledge that grows with
                  your team's experiences, making it easy to find answers and
                  share expertise across the organization.`}
                </p>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-lg border bg-background p-2">
            <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
              {/* Replace with an appropriate SVG icon */}
              <svg viewBox="0 0 24 24" className="h-12 w-12 fill-current">
                {/* SVG path here */}
              </svg>
              <div className="space-y-2">
                <h3 className="font-bold">Real-time Collaboration Tools</h3>
                <p className="text-sm text-muted-foreground">
                  Collaborate seamlessly with team members in real-time,
                  ensuring that insights and updates are shared instantly,
                  enhancing teamwork and productivity.
                </p>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-lg border bg-background p-2">
            <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
              {/* Replace with an appropriate SVG icon */}
              <svg viewBox="0 0 24 24" className="h-12 w-12 fill-current">
                {/* SVG path here */}
              </svg>
              <div className="space-y-2">
                <h3 className="font-bold">Advanced Analytics and Reporting</h3>
                <p className="text-sm text-muted-foreground">
                  {`Get detailed analytics and reports on how your knowledge base
                  is being used. Track key metrics to continually improve and
                  tailor your resources to your team's needs.`}
                </p>
              </div>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-lg border bg-background p-2">
            <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
              {/* Replace with an appropriate SVG icon */}
              <svg viewBox="0 0 24 24" className="h-12 w-12 fill-current">
                {/* SVG path here */}
              </svg>
              <div className="space-y-2">
                <h3 className="font-bold">Secure and Scalable</h3>
                <p className="text-sm text-muted-foreground">
                  Built with security and scalability in mind, ensuring that
                  your data is protected and that QuiklyAI grows alongside your
                  organization, no matter the size.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="community" className="container py-8 md:py-12 lg:py-24">
        <div className="mx-auto flex max-w-[58rem] flex-col items-center justify-center gap-4 text-center">
          <h2 className="font-heading text-3xl leading-[1.1] sm:text-3xl md:text-6xl">
            Join the QuiklyAI Community
          </h2>
          <p className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
            Discover how teams are leveraging QuiklyAI to enhance their sales
            engineering. Get started, share ideas, and learn from the community.
            <br />
            Connect with us on{" "}
            <Link
              href={asyncSiteConfig.links.github}
              target="_blank"
              rel="noreferrer"
              className="underline underline-offset-4"
            >
              GitHub
            </Link>{" "}
            and{" "}
            <Link
              href={asyncSiteConfig.links.twitter}
              target="_blank"
              rel="noreferrer"
              className="underline underline-offset-4"
            >
              Twitter
            </Link>
            .
          </p>
        </div>
      </section>
    </>
  );
}
