import React, { useEffect, useState } from "react";
import Link from "next/link";

import { marketingConfig } from "@/config/marketing";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { MainNav } from "@/components/main-nav";
import { SiteFooter } from "@/components/site-footer";
import { type MarketingConfig } from "~/types";
import Landing from "./landing";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

export default function MarketingLayout() {
  const [asyncData, setAsyncData] = useState<MarketingConfig>();
  useEffect(() => {
    // Simulate fetching data
    async function fetchData() {
      const config = await Promise.resolve(marketingConfig);
      setAsyncData(config);
    }

    fetchData().catch(console.error);
  }, []);

  const router = useRouter();
  const { data: session } = useSession();

  if (session?.user) {
    router.push("/projects").catch(console.error);
  }

  return (
    <div className="flex min-h-screen flex-col">
      <header className="container z-40 bg-background">
        <div className="flex h-20 items-center justify-between py-6">
          <MainNav items={asyncData?.mainNav} />
          <nav>
            <Link
              href="/login"
              className={cn(
                buttonVariants({ variant: "secondary", size: "sm" }),
                "px-4",
              )}
            >
              Login
            </Link>
          </nav>
        </div>
      </header>
      <main className="flex-1">
        <Landing />
      </main>
      <SiteFooter />
    </div>
  );
}
