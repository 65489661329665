import React from "react";
import cn from "classnames";
import { ModeToggle } from "@/components/mode-toggle";
import { Logo } from "./logo";

export function SiteFooter({ className }: React.HTMLAttributes<HTMLElement>) {
  return (
    <footer className={cn(className)}>
      <div className="container flex flex-col items-center justify-between gap-4 py-10 md:h-24 md:flex-row md:py-0">
        <ModeToggle />
        <div className="flex flex-col items-center gap-4 px-8 md:flex-row md:gap-2 md:px-0">
          <Logo />
          <p className="text-center text-sm leading-loose md:text-left">
            Quikly {new Date().getFullYear()}. All rights reserved.
          </p>
        </div>
        <div />
      </div>
    </footer>
  );
}
